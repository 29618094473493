var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contribute-team wrap"},[_vm._m(0),_c('div',{staticClass:"cg-bd"},[_c('div',{staticClass:"hd clearfix"},[_c('aside',[_vm._v(_vm._s(_vm.teamInfo.teamName))]),_c('section',[_vm._v(" 提示：成员上限"),_c('span',[_vm._v(_vm._s(_vm.matchInfo.team_member_num))]),_vm._v("人，共可上传"),_c('span',[_vm._v(_vm._s(_vm.matchInfo.team_works_total))]),_vm._v("张作品 ")])]),_c('div',{staticClass:"bd"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:index == 0 ? 'person team-item' : 'team-item'},[_c('div',{staticClass:"h"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.avatar,"alt":""}})]),_c('div',{staticClass:"text"},[_c('div',{staticClass:"t"},[(item.type == 1)?_c('i',{staticClass:"ico ico-leader"}):_vm._e(),_vm._v(_vm._s(item.nickname)+" ")]),_c('div',{staticClass:"p"},[_c('div',{staticClass:"left"},[_c('span',[_c('i',{staticClass:"ico ico-tel"}),_c('span',[_vm._v(_vm._s(item.mobile))])]),_c('span',[_c('i',{staticClass:"ico ico-name"}),_c('span',[_vm._v(_vm._s(item.realname))])]),_c('span',[_c('i',{staticClass:"ico ico-location"}),_c('span',[_vm._v(_vm._s(item.province)+" "+_vm._s(item.city))])])]),_c('div',{staticClass:"right"},[_vm._v(" 已选择"),_c('span',[_vm._v(_vm._s(item.imgs.length))]),_vm._v("还可以选择"),_c('span',[_vm._v(_vm._s(_vm.matchInfo.team_works_num - item.imgs.length))]),_vm._v("张 ")])])])]),_c('div',{staticClass:"b"},[(
              item.imgs.length <= 0 &&
                _vm.teamInfo.workscount < _vm.matchInfo.team_works_total &&
                 _vm.isCaptain
            )?_c('div',{staticClass:"file-single",on:{"click":function($event){return _vm.addWorks(item)}}},[_vm._m(1,true)]):_c('ul',{staticClass:"file-list"},[_vm._l((item.imgs),function(li,liIndex){return _c('li',{key:liIndex},[_c('div',{staticClass:"img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(li.image),expression:"li.image"}],attrs:{"alt":""}})]),(_vm.isCaptain)?_c('div',[_c('div',{staticClass:"edit",on:{"click":function($event){return _vm.edit(li, item)}}},[_vm._v("编辑")]),_c('div',{staticClass:"del",on:{"click":function($event){return _vm.del(li, item)}}})]):_vm._e()])}),(
                item.imgs.length < _vm.matchInfo.team_works_num &&
                  _vm.teamInfo.workscount < _vm.matchInfo.team_works_total &&
                  _vm.isCaptain
              )?_c('li',{staticClass:"add",on:{"click":function($event){return _vm.addWorks(item)}}},[_vm._m(2,true)]):_vm._e()],2)])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cg-hd"},[_c('span',[_vm._v("战队投稿")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-item"},[_c('i',{staticClass:"ico ico-add"}),_c('p',{staticClass:"t1"},[_vm._v("点击添加作品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-item"},[_c('i',{staticClass:"ico ico-add"}),_c('p',{staticClass:"t1"},[_vm._v("点击添加作品")])])
}]

export { render, staticRenderFns }