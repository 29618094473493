<template>
  <div class="content">
    <div class="wrap">
      <div class="match-month">
        <div class="img">
          <img :src="cover" alt="" />
        </div>
      </div>
      <ul class="match-list clearfix">
        <li v-for="item in matchList" :key="item.id">
          <router-link :to="'/detail/' + item.id">
            <div :class="['pic',item.is_wait?'pic-wait':'']">
                <img :src="item.image" />
                <div class="wait-text" v-if="item.is_wait">赛事待开启</div>
            </div>
            <div class="title-1">{{ item.mname }}</div>
            <div class="title-2">{{ item.sponsor }}</div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cover: "",
      id: this.$route.params.id,
      matchList: [
        // {
        //   link: "",
        //   img: require("@/static/res/images/pscase_before5.jpg"),
        //   title: "标题标题标题标题标题标题",
        //   subTitle: "主办：几十块较好的333"
        // }
      ]
    };
  },
  methods: {
    init() {
      // this.axios.get("/match/getMoonMatchList?id=" + this.id).then(res => {
      this.axios.post("/match/getMoonMatchList",{id:this.id}).then(res => {
        // console.log(res);
        this.cover = res.data.data.info.image;
        this.matchList = res.data.data.list;
      });
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss">
.match-list li {
    cursor: pointer;
    .pic-wait{
      position: relative;
      img{
        filter:blur(8px)
      }
      .wait-text{
        width:160px;
        height:90px;
        position:absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background:rgba(255,255,255,.2);
        text-align: center;
        line-height: 90px;
        color: #fff;
        font-size: 18px;
        text-shadow: 0 0 10px rgba(0,0,0,.7);
      }
    }
  }
</style>
