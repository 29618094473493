<template>
  <div class="contribute-team wrap">
    <div class="cg-hd"><span>战队投稿</span></div>
    <div class="cg-bd">
      <div class="hd clearfix">
        <aside>{{ teamInfo.teamName }}</aside>
        <section>
          提示：成员上限<span>{{matchInfo.team_member_num}}</span>人，共可上传<span>{{matchInfo.team_works_total}}</span>张作品
          <!-- <el-button class="btn-orange">保存提交</el-button> -->
        </section>
      </div>
      <div class="bd">
        <div
          :class="index == 0 ? 'person team-item' : 'team-item'"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="h">
            <div class="img">
              <img :src="item.avatar" alt="" />
            </div>
            <div class="text">
              <div class="t">
                <i class="ico ico-leader" v-if="item.type == 1"></i
                >{{ item.nickname }}
              </div>
              <div class="p">
                <div class="left">
                  <span
                    ><i class="ico ico-tel"></i
                    ><span>{{ item.mobile }}</span></span
                  >
                  <span
                    ><i class="ico ico-name"></i
                    ><span>{{ item.realname }}</span></span
                  >
                  <span
                    ><i class="ico ico-location"></i
                    ><span>{{ item.province }} {{ item.city }}</span></span
                  >
                </div>
                <div class="right">
                  已选择<span>{{ item.imgs.length }}</span
                  >还可以选择<span>{{ matchInfo.team_works_num - item.imgs.length }}</span
                  >张
                </div>
              </div>
            </div>
          </div>
          <div class="b">
            <div
              class="file-single"
              @click="addWorks(item)"
              v-if="
                item.imgs.length <= 0 &&
                  teamInfo.workscount < matchInfo.team_works_total &&
                   isCaptain
              "
            >
              <div class="upload-item">
                <i class="ico ico-add"></i>
                <p class="t1">点击添加作品</p>
                <!-- <p class="b1">
                  <span>格式：JPG</span><span>大小：小于20M</span>
                </p> -->
              </div>
            </div>

            <ul class="file-list" v-else>
              <li v-for="(li, liIndex) in item.imgs" :key="liIndex">
                <div class="img"><img v-lazy="li.image" alt="" /></div>
                <div v-if="isCaptain">
                  <div class="edit" @click="edit(li, item)">编辑</div>
                  <div class="del" @click="del(li, item)"></div>
                </div>
              </li>
              <li
                class="add"
                @click="addWorks(item)"
                v-if="
                  item.imgs.length < matchInfo.team_works_num &&
                    teamInfo.workscount < matchInfo.team_works_total &&
                    isCaptain
                "
              >
                <div class="upload-item">
                  <i class="ico ico-add"></i>
                  <p class="t1">点击添加作品</p>
                  <!-- <p class="b1">
                    <span>格式：JPG</span><br /><span>大小：小于20M</span>
                  </p> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AMap from "@/components/AMap";
export default {
  components: {
    // AMap,
  },
  data() {
    return {
      id: this.$route.params.id,
      gid: this.$route.query.gid,
      isShowForm: false,
      form: {
        name: "",
        addreses: "",
        describe: "",
      },
      infoRules: {
        name: [{ required: true, message: "标题不能为空", trigger: "blur" }],
      },

      isShowAMap: false,
      location: "点击选择拍摄地点",
      tid: this.$route.query.tid,
      uid: 0,
      teamInfo: {},
      matchInfo: {
        team_member_num: 0,
        team_works_num: 0,
        team_works_total: 0,
      },
      isAllowEdit: false,
      list: [],
      isCaptain: false,
    };
  },
  methods: {
    addWorks(item) {
      // console.log(item);
      this.$router.push({
        path: "/contribute/" + this.id + "/0/team",
        query: {
          tid: this.tid,
          id: item.uid,
          gid: this.gid,
        },
      });
    },
    getLocation() {
      this.isShowAMap = true;
    },
    changeLocation(location) {
      if (location != "" && location != undefined) {
        this.location = location;
      }
      this.isShowAMap = false;
    },
    add() {
      this.$router.push({
        path: `/contribute/${this.id}/0/team`,
        // query: { group: gid },
      });
    },
    edit(li, item) {
      // console.log(li, item);
      this.$router.push({
        path: "/contribute/" + this.id + "/0/team",
        query: {
          work_id: li.mi_id,
          id: item.uid,
          tid: item.tid,
          gid: this.gid,
        },
      });
    },
    del(li, item) {
      // console.log(li, item);
      this.$confirm("您确定要删除该作品以及相关信息吗？是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(this.tools.api.delGroupWorks, {
              work_id: li.mi_id,
              uid: item.uid,
            })
            .then((res) => {
              // console.log(res);
              if (res.data.code == 200 && res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getList() {
      this.axios
        .post(this.tools.api.teamInfo, { tid: this.tid })
        .then((res) => {
          // this.list = res.data.data.member;
          this.teamInfo = res.data.data.team;
          this.matchInfo = res.data.data.match;
          // console.log(this.teamInfo);
          let list = res.data.data.member;
          let curIndex = 0;
          for (let i = 0, len = list.length; i < len; i++) {
            if (list[i].uid == this.uid) {
              curIndex = i;
              break;
            }
          }
          if (curIndex != 0) {
            let item = [];
            item[0] = list[curIndex];
            list.splice(curIndex, 1);
            this.list = item.concat(list);
          } else {
            this.list = list;
          }
          this.isCaptain = this.list[0].type == 1;
          // console.log(this.list);
        });
    },
  },
  created() {
    this.axios.post("/match/checktUserInfo").then(
      (res) => {
        if (res.data.code == 200) {
          // 已登录
          this.$store.commit("changeLogin", true);
          // 判断基础信息是否完整
          // console.log(res.data.data);
          this.uid = res.data.data.uid;
          this.getList();
        }
      },
      (error) => {
        // 跳转到登录
        console.log(error);
        // this.tools.ajax_error(error);
        this.$router.push({
          name: "intro",
          params: {
            id: this.id,
          },
        });
      }
    );
  },
};
</script>

<style lang="scss">
$bgGroup: url("../../static/images/20200609/bg-group.png") no-repeat;
.contribute-team {
  background: #f3f5f9;
  margin-bottom: 50px;
  .ico {
    background: $bgGroup;
    &.ico-leader {
      background-position: 0 -140px;
      width: 75px;
      height: 25px;
    }
    &.ico-tel {
      background-position: -150px -80px;
      width: 20px;
      height: 20px;
    }
    &.ico-name {
      background-position: -190px -80px;
      width: 27px;
      height: 20px;
    }
    &.ico-location {
      background-position: -240px -80px;
      width: 18px;
      height: 18px;
    }
  }

  .cg-hd {
    height: 112px;
    span {
      top: 46px;
      padding-left: 25px;
      font-size: 34px;
      position: relative;
      line-height: 36px;
      height: 36px;

      &::after {
        content: "";
        width: 4px;
        height: 34px;
        overflow: hidden;
        display: block;
        position: absolute;
        top: 7px;
        left: 0;
        border-radius: 4px;
        background-color: #de8064;
      }
    }
  }
  .cg-bd {
    background: #fff;
    border-radius: 4px;
    .hd {
      border-bottom: 1px solid #f3f5f9;
      line-height: 110px;
      height: 110px;
      overflow: hidden;
      padding: 0 30px;
      aside,
      section {
        vertical-align: middle;
      }
      aside {
        font-size: 28px;
        position: relative;
        padding: 0 44px;
        max-width: 500px;
        white-space: nowrap;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;

        &::before,
        &::after {
          content: "";
          width: 30px;
          height: 18px;
          background: $bgGroup;
          position: absolute;
          top: 47px;
        }
        &::before {
          left: 0;
        }
        &::after {
          background-position: -40px 0;
          right: 0;
        }
      }
      section {
        text-align: right;
        float: right;
        font-size: 20px;
        color: #666;
        & > span {
          color: #f77e5e;
          margin: 0 5px;
        }
        & .btn-orange {
          top: -2px;
          position: relative;
        }
      }
    }
    .team-item {
      padding: 30px;
      .h {
        overflow: hidden;
        .img {
          float: left;
          margin-right: 22px;
          width: 64px;
          height: 64px;
          padding: 4px;
          border-radius: 50%;
          background: #000;
          background-image: linear-gradient(to bottom right, #ff5026, #ffcdb5);
          img {
            background: #000;
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
          }
        }
        .text {
          padding: 2px 0 0;
          .t {
            color: #666;
            font-size: 22px;
            .ico {
              margin-right: 5px;
            }
          }
          .p {
            padding: 8px 0 0;
            font-size: 18px;
            .left {
              background: #f3f5f9;
              color: #999;
              height: 36px;
              line-height: 36px;
              padding: 0 5px;
              border-radius: 18px;
              &:hover {
                color: #666;
              }
              .ico {
                margin: 9px 5px 0 0;
              }
              & i,
              & span {
                vertical-align: top;
              }
              & > span {
                padding: 0 15px;
              }
            }
            .right {
              color: #999;
              float: right;
              span {
                color: #333;
                font-size: 21px;
                margin: 0 5px;
              }
            }
          }
        }
      }
      .b {
        .file-single,
        .file-list li {
          height: 187px;
        }
        .file-single {
          padding: 22px 0 0;
        }
        .upload-item {
          box-sizing: border-box;
          border: 2px dashed #cac9c9;
          background: #f3f5f9;
          border-radius: 4px;
          text-align: center;
          width: 100%;
          height: 100%;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            border-color: #f77e5e;
          }
          .ico-add {
            background-position: -60px -50px;
            width: 61px;
            height: 61px;
            margin-top: 40px;
          }
          .t1 {
            font-size: 20px;
            line-height: 60px;
            color: #666;
          }
          .b1 {
            font-size: 16px;
            line-height: 24px;
            color: #999;
          }
        }
        .file-list {
          overflow: hidden;
          li {
            border-radius: 4px;
            float: left;
            background: #f3f5f9;
            width: 252px;
            margin: 15px 15px 0 0;
            position: relative;
            overflow: hidden;
            .t1 {
              font-size: 18px;
              line-height: 54px;
            }
            .b1 {
              font-size: 14px;
              line-height: 20px;
            }
            .img {
              width: 100%;
              height: 100%;
              img {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
              }
            }
            .edit,
            .del {
              position: absolute;
              transition: all 0.2s ease;
              cursor: pointer;
            }
            .edit {
              background: rgba(0, 0, 0, 0.5);
              color: #fff;
              text-align: center;
              height: 40px;
              line-height: 40px;
              font-size: 16px;
              bottom: -40px;
              left: 0;
              width: 100%;
            }
            .del {
              background: $bgGroup -110px -140px;
              width: 22px;
              height: 22px;
              top: 5px;
              right: 5px;
              top: -27px;
            }
            &:hover {
              .edit {
                bottom: 0;
              }
              .del {
                top: 5px;
              }
            }
          }
          li:nth-child(5n) {
            margin-right: 0;
          }
          .ico-add {
            background-position: 0 -50px;
            width: 50px;
            height: 50px;
            margin-top: 44px;
          }
        }
      }
    }
    .person {
      background: #fff8f6;
      .ico {
        &.ico-tel {
          background-position: -150px -50px;
        }
        &.ico-name {
          background-position: -190px -50px;
        }
        &.ico-location {
          background-position: -240px -50px;
        }
      }
      .h .text .p .left {
        background: #fff;
        color: #ea8c70;
        &:hover {
          color: #f88769;
        }
      }
    }
  }
  .el-dialog__header {
    background: #f07f48;
    padding: 10px 15px;
    span,
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
    .el-dialog__headerbtn {
      font-size: 28px;
      top: 8px;
      right: 10px;
    }
  }
  .upload-form .el-dialog {
    width: 606px;
    .el-dialog__body {
      padding: 20px 19px;
    }
    .img {
      width: 100%;
      height: 414px;
      background: #ddd;
      overflow: hidden;
      border-radius: 8px;
      position: relative;
      cursor: pointer;
      margin-bottom: 20px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      p {
        position: absolute;
        bottom: -50px;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        text-align: center;
        font-size: 18px;
        height: 50px;
        line-height: 50px;
        transition: all 0.2s ease;
      }
      &:hover p {
        bottom: 0;
      }
    }
    .el-form-item__label {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    textarea,
    textarea::-webkit-input-placeholder {
      font-family: "Micro Yahei", sans-serif;
    }
    .address-value {
      display: none;
    }
    .address {
      position: relative;
      cursor: pointer;
      padding: 0 0 0 23px;
      color: #f77e5e;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -3px;
        background: $bgGroup -270px -140px;
        width: 24px;
        height: 24px;
      }
      // .el-input__inner {
      //   cursor: pointer;
      //   border: 0;
      //   padding-left: 25px;
      // }
    }
  }
}
.btn-orange {
  background: #f77e5e;
  color: #fff;
  border-color: #f77e5e;
  &:hover {
    background: #fd9676;
    border-color: #fd9676;
    color: #fff;
  }
}
@media screen and (max-width: 1600px) {
  .contribute-team .cg-bd .bd .team-item .b .file-list li {
    width: 216px;
  }
  .contribute-team .cg-bd .bd .team-item .b .file-single,
  .contribute-team .cg-bd .bd .team-item .b .file-list li {
    height: 160px;
  }
  .contribute-team .cg-bd .team-item .b .upload-item {
    .ico-add {
      margin-top: 30px;
    }
    .t1 {
      font-size: 18px;
      line-height: 42px;
    }
    .b1 {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .contribute-team .cg-bd .team-item .b li .upload-item {
    .ico-add {
      margin-top: 34px;
    }
    .t1 {
      font-size: 16px;
      line-height: 42px;
    }
    .b1 {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
